const randomString = () => {
    let len = 32;
    let chars = 'ABCDEFGHIJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = chars.length;
    let character = '';
    for (let i = 0; i < len; i++) {
        character += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return character
}

function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//打乱数组
const shuffle = (arr) => {
    let _arr = arr.slice();
    for (let i = 0; i < _arr.length; i++) {
        let j = getRandomInt(0, i);
        let t = _arr[i];
        _arr[i] = _arr[j];
        _arr[j] = t;
    }
    return _arr;
}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : `0${n}`
}

// 判断手机系统
const isAndroid=()=>{
    let u = navigator.userAgent
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
    if (isAndroid) {
        return true
    } else {
        return false
    }
}
const bottomLoading=(div,func)=>{
    // console.log('bottom',div);
    const options = {
      root: null, //指定根元素，用于检查目标的可见性。必须是目标元素的父级元素。如果未指定或者为null，则默认为浏览器视窗。
      rootMargin: '0px', //根元素的外边距，类似于 CSS 中的margin属性
      threshold: 0.1 //目标元素与根元素的交叉比例，可以是单一的 number 也可以是 number 数组，比如，[0, 0.25, 0.5, 0.75, 1]就表示当目标元素 0%、25%、50%、75%、100% 可见时，会触发回调函数。
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
            func();  // 当底部元素进入视窗时触发加载下一页数据
        }
      });
    }, options);
    observer.observe(div);  // 监听底部元素
  }

module.exports = {
    randomString, shuffle, formatNumber,isAndroid,bottomLoading
}