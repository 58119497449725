import {request} from '@/utils/request'
// 登录
/* export function login (data) {
    return request({
        url: '/h5/user/login',
        method: 'post',
        data
    })
} */

// h5登录
export function h5Login(data){
    return request({
        url:'/h5/user/htmlLogin',
        method:'post',
        data
    })
}

// 微信登录授权获取code
export function wxAuthorization(params){
    return request({
        url:'/wechatPub/autoLogin2',
        method:'get',
        params
    })
}

// 微信获取code登录
export function wxLogin(params){
    return request({
        url:'/h5/user/getWxUserInfoByCode',
        method:'get',
        params
    })
}


// 微信分享获取接口
export function WxShare(params){
    return request({
        url:'/wechatPub/wxPublicShare',
        method:'get',
        params
    })
}

// 手机号码登录
export function loginByPhone (data) {
    return request({
        url: '/orgUser/loginByPhone',
        method: 'post',
        data

    })
}
// 账号密码登录
export function login (data) {
    return request({
        url: '/orgUser/login',
        method: 'post',
        data

    })
}
// ----------------------------企微接口 青少年学校段接口--------------------------------------
// 企业微信账号关联
export function bindUser(data) {
    return request({
      url: '/orgWecom/bindUser',
      method: 'post',
      data
    })
  }
  
  // 企业微信账号注册
  export function wxworkRegister(data) {
    return request({
      url: '/orgWecom/regUser',
      method: 'post',
      data
    })
  }
  // 上传文件
  export function upload(data) {
    return request({
      url: '/upload',
      method: 'post',
      data
    })
  }
  // 增加附件
 export function addAccessory (data) {
     return request({
         url: '/orgStudentDoc/add',
         method: 'POST',
         data
     })
 }

  // 个人信息
export function getInfo () {
    return request({
        url: '/orgUser/info',
        method: 'get'
    })
}
// 主页信息
export function home () {
    return request({
        url: '/orgUser/home',
        method: 'get'
    })
}
// 获取验证码
export function getVcode (data) {
    return request({
        url: '/orgUser/getVcode',
        method: 'post',
        data
    })
}
// 更新手机号
export function phoneUpdate (data) {
    return request({
        url: '/orgUser/phoneUpdate',
        method: 'post',
        data
    })
}
// 更新学校信息
export function orgUpdate (data) {
    return request({
        url: '/orgUser/orgUpdate',
        method: 'post',
        data
    })
}
