import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'lib-flexible'
import 'vant/lib/index.less'
import '@/styles/index.less'
// import vConsole from './utils/vconsole'
import LiquorTree from 'liquor-tree'

Vue.use(LiquorTree)
import { ShareSheet,Overlay,Area,ImagePreview,Uploader,Switch,Tab,Tabs,Search,Calendar,Badge ,Toast, DatetimePicker,Swipe,List , SwipeItem, Notify, Col,Row,Image as VanImage, Button, Empty, Dialog, Form, Field, CellGroup, RadioGroup, Radio, Stepper, Picker, Popup, Tag, Icon, Checkbox, CheckboxGroup, Tabbar, TabbarItem, Progress } from 'vant'
Vue.use(Notify)
Vue.use(Badge)
Vue.use(Overlay);
Vue.use(VanImage)
Vue.use(Area)
Vue.use(Button)
Vue.use(Empty)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Uploader)
Vue.use(Switch)
Vue.use(ImagePreview);
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Stepper)
Vue.use(Picker)
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup)
Vue.use(Tag)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Progress)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Search);
Vue.use(Toast)
Vue.use(ShareSheet)
Vue.use(Col)
Vue.use(Row);
Vue.use(List);
Vue.use(DatetimePicker);
Vue.use(Calendar);
 
Vue.prototype.$onVisible = (callback) => {
    document.addEventListener('visibilitychange', function () {
        callback(document.visibilityState)
    })
}
Vue.config.productionTip = false
// 监听页面状态

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')


