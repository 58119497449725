import {request} from '@/utils/request'
// 列表
export function studentList (params) {
    return request({
        url: '/orgStudent/list',
        method: 'get',
        params
    })
}

// 详情
export function studentDetail (params) {
    return request({
        url: '/orgStudent/detail',
        method: 'get',
        params
    })
}

// 学生档案更新V2
export function profileUpdateV2 (data) {
  return request({
      url: '/orgStudent/updateV2',
      method: 'post',
      data
  })
}

// 删除
export function studentRemove (params) {
    return request({
        url: '/orgStudent/remove',
        method: 'get',
        params
    })
}