<template>
  <div id="app">
    <router-view v-if="isRouterAlive" :key="key" />
    <!-- <van-dialog
      v-model:show="isNeedPhone"
      title="请补充手机号码"
      @confirm="login"
      confirmButtonColor="#fdaf55"
      :overlay="true"
    >
      <div style="padding: 20px 0">
        <van-field
          v-model="phone"
          type="tel"
          label="手机号码"
          placeholder="请输入手机号码"
        />
      </div>
    </van-dialog> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { login } from "@/api/user";
export default {
  name: "App",
  data() {
    return {
      phone: "",
      isRouterAlive: true,
    };
  },
  computed: {
    ...mapGetters(["isNeedPhone"]),
    key(){
      return this.$route.path+Math.random()
    }
  },
  methods: {
    login() {
      if (!/^[1][2,3,4,5,6,7,8][0-9]{9}$/.test(this.phone)) {
        this.$notify("号码格式不对");
        return;
      }
      let loginInfo = window.localStorage.getItem("loginInfo");
      loginInfo = JSON.parse(loginInfo);
      loginInfo["phone"] = this.phone;
      login(loginInfo)
        .then((res) => {
          let msg = res.msg;
          if (msg !== "登录成功") {
            this.$notify(msg);
          } else {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="less">
#app {
  width: 100%;
  height: 100%;
}
</style>