import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index'
import wx from "weixin-js-sdk";
import { h5Login,wxLogin,getInfo,loginByPhone,login} from '@/api/user'
import { studentDetail } from '@/api/student';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: sessionStorage.getItem('wx_token')||'',
        // token: 'm1UzxYdQNaYdQF1pKu0DIySgd4t3hbDX',
        tabs: [
            {
                selectedIconPath: require('@/assets/tabbar/home_a.png'),
                iconPath: require('@/assets/tabbar/home_d.png'),
                pagePath: "/home",
                text: "首页"
            },
            {
                selectedIconPath: require('@/assets/tabbar/exam_a.png'),
                iconPath: require('@/assets/tabbar/exam_d.png'),
                pagePath: "/project/index",
                text: "测评"
            },
            {
                selectedIconPath: require('@/assets/tabbar/consult_a.png'),
                iconPath: require('@/assets/tabbar/consult_d.png'),
                pagePath: "/warn/index",
                text: "预警"
            },
            {
                selectedIconPath: require('@/assets/tabbar/my_a.png'),
                iconPath: require('@/assets/tabbar/my_d.png'),
                pagePath: "/my",
                text: "管理"
            }
        ],
        pageCode: '',
        isNeedPhone: false,
        phone:'',
        id:sessionStorage.getItem('wx_userId')||'',
        nickName:sessionStorage.getItem('wx_nickName')||'',
        avatarUrl:sessionStorage.getItem('wx_avatarUrl')||'',
        openId:sessionStorage.getItem('wx_openId')||'',
        student:JSON.parse(localStorage.getItem('student_file'))||'',
        gdOriginBool: false,
        qtOriginBool: false,
        xyOriginBool: false,
        originBool: false,
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            // state.token = 'm1UzxYdQNaYdQF1pKu0DIySgd4t3hbDX'
            window.sessionStorage.setItem('wx_token',token)
        },
        SET_PAGECODE: (state, pageCode) => {
            state.pageCode = pageCode
        },
        SET_ISNEEDPHONE: (state, isNeedPhone) => {
            state.isNeedPhone = isNeedPhone
        },
        SET_PHONE: (state, phone) => {
            state.phone = phone
        },
        SET_ID: (state, id) => {
            state.id = id
        },
        SET_QUIT:(state,data)=>{
            state.id=data.id
            state.token=data.token
            state.phone=data.phone
        },
        SET_NICKNAME:(state,nickName)=>{
            state.nickName=nickName
        },
        SET_AVATARURL:(state,avatarUrl)=>{
            state.avatarUrl=avatarUrl
        },
        SET_OPENID:(state,openId)=>{
            state.openId=openId
        },
        SET_STUDENT:(state,student)=>{
            state.student=student
        },
        SET_ORIGINBOOL: (state, originBool) => {
            state.originBool = originBool
        },
        SET_XYORIGINBOOL: (state, xyOriginBool) => {
            state.xyOriginBool = xyOriginBool
        },
        SET_QTORIGINBOOL: (state, qtOriginBool) => {
            state.qtOriginBool = qtOriginBool
        },
        SET_GDORIGINBOOL: (state, gdOriginBool) => {
            state.gdOriginBool = gdOriginBool
        },
    },
    actions: {
        setToken({ commit }, token) {
            commit('SET_TOKEN', token)
        },
        login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    const { data } = response
                    console.log(data, data.token)
                    window.sessionStorage.setItem('token', data.token)
                    commit('SET_TOKEN', data.token)
                    commit('SET_ISNEEDPHONE', data.isNeedPhone || false)
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        h5login({commit},userInfo){
            return new Promise((resolve, reject) => {
                h5Login(userInfo).then(response=>{
                    const {data} =response
                    console.log(data);
                    window.sessionStorage.setItem('h5_token', data.token)
                    commit('SET_TOKEN', data.token)
                    // commit('SET_PHONE', data.users.phone)
                    commit('SET_ID', data.userId)
                    resolve(data)
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        weChatLogin({commit},obj){
            return new Promise((resolve, reject) => {
                wxLogin({code:obj.code}).then(response=>{
                    const {data} =response
                    console.log(data);
                    window.sessionStorage.setItem('wx_token', data.token)
                    window.sessionStorage.setItem('wx_id', data.userId)
                    window.sessionStorage.setItem('wx_nickName', data.nickName)
                    window.sessionStorage.setItem('wx_avatarUrl', data.avatarUrl)
                    window.sessionStorage.setItem('wx_openId', data.openId)
                    commit('SET_TOKEN', data.token)
                    // commit('SET_PHONE', data.users.phone)
                    commit('SET_ID', data.userId)
                    commit('SET_NICKNAME', data.nickName)
                    commit('SET_AVATARURL', data.avatarUrl)
                    commit('SET_OPENID', data.openId)
                    console.log(131,obj);
                    console.log(132,obj.backUrl);
                    router.push(obj.backUrl)
                    resolve(data)
                }).catch(error=>{
                    reject(error)
                })
            })
        },
        // 企业微信存储token
        wxSetToken({commit, state}, token) {
            return new Promise((resolve, reject) => {
              commit('SET_TOKEN', token)
              console.log('wxtoken',token)
              window.sessionStorage.setItem('wx_token',token)
              console.log('this.$route.query.token',window.sessionStorage.getItem('wx_token'));
              router.push('/home')
              resolve()
              // console.log(state.token)
          })
        },
        async getStudentDetail({commit,state},id){
            const res = await studentDetail({id})
            localStorage.setItem('student_file',JSON.stringify(res.data.vo))
            commit('SET_STUDENT',res.data.vo)
        },
        // 浏览器账号密码登录
        login ({ commit }, userInfo) {
            const { username, password } = userInfo
            return new Promise((resolve, reject) => {
                login({ username: username.trim(), password: password }).then(response => {
                    const { data } = response
                    // console.log(data, data.token)
                    commit('SET_TOKEN', data.token)
                    // setToken(data.token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 浏览器手机号码登录
        loginByPhone ({ commit }, userInfo) {
            const { phone, vcode } = userInfo
            return new Promise((resolve, reject) => {
                loginByPhone({ phone, vcode }).then(response => {
                    const { data } = response
                    console.log(data, data.token)
                    commit('SET_TOKEN', data.token)
                    setToken(data.token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        token: state => {
            return state.token
        },
        id: state => {
            return state.id
        },
        phone: state => {
            return state.phone
        },
        tabs: state => {
            return state.tabs
        },
        pageCode: state => {
            return state.pageCode
        },
        isNeedPhone: state => {
            return state.isNeedPhone
        }
    }
})
 