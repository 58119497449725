import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { randomString } from '@/utils/util'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import nprogress from 'nprogress'
import {isAndroid} from '@/utils/util'
// import { wexinShare,getWxConfig } from '@/utils/wxShare.js'
// import { wxAuthorization } from '@/api/user'
NProgress.configure({ showSpinner: false })
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/login',
        name: '/Login',
        component: () => import('@/views/login/index'),
        meta: {
            title: '登录',
            isShare: false,
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: {
            title: '首页',
            isShare: false,
        }
    }, 
    {
        path: '/project',
        name: 'Project',
        redirect: '/project/index',
        component: () => import('@/views/index.vue'),
        children: [
            {
                path: 'index',
                component: () => import('@/views/project/index.vue'),
                name: 'ProjectIndex',
                meta: {
                    title: '测评',
                    isShare: false,
                }
            },
            {
                path: 'add',
                component: () => import('@/views/project/add.vue'),
                name: 'ProjectAdd',
                meta: {
                    title: '发布测评',
                    isShare: false,
                }
            },
            {
                path: 'select',
                component: () => import('@/views/project/select.vue'),
                name: 'ProjectSelect',
                meta: {
                    title: '选择年级/班级',
                    isShare: false,
                }
            },
            {
                path: 'success',
                component: () => import('@/views/project/success.vue'),
                name: 'ProjectSuccess',
                meta: {
                    title: '发布成功',
                    isShare: false,
                }
            },
            {
                path: 'statistics',
                component: () => import('@/views/project/statistics.vue'),
                name: 'ProjectStatistics',
                meta: {
                    title: '测评统计',
                    isShare: false,
                }
            },
            {
                path: 'reportDetail',
                component: () => import('@/views/project/reportDetail.vue'),
                name: 'ProjectReportDetail',
                meta: {
                    title: '报告详情',
                    isShare: false,
                }
            },
            {
                path: 'classReport',
                component: () => import('@/views/project/classReport.vue'),
                name: 'ProjectClassReport',
                meta: {
                    title: '班级报告',
                    isShare: false,
                }
            },
            {
                path: 'classFeedback',
                component: () => import('@/views/project/class-feedback.vue'),
                name: 'ProjectClassFeedback',
                meta: {
                    title: '班级报告反馈',
                    isShare: false,
                }
            },
            {
                path: 'expertReport',
                component: () => import('@/views/project/expertReport.vue'),
                name: 'ProjectExpertReport',
                meta: {
                    title: '专家报告',
                    isShare: false,
                }
            },
            
        ]
    }, 
    {
        path: '/warn',
        name: 'Warn',
        redirect: '/warn/index',
        component: () => import('@/views/index.vue'),
        children:[
            {
                path: 'index',
                component: () => import('@/views/warn/index.vue'),
                name: 'Wain-Index',
                meta: {
                    title: '预警',
                    isShare: false,
                }
            },
            {
                path: 'surveyDetail',
                component: () => import('@/views/warn/survey-detail.vue'),
                name: 'Wain-SurveyDetail',
                meta: {
                    title: '联动观察详情',
                    isShare: false,
                }
            },
            {
                path: 'crisisTask',
                component: () => import('@/views/warn/crisis-task.vue'),
                name: 'Wain-CrisisTask',
                meta: {
                    title: '干预方案',
                    isShare: false,
                }
            },
        ],
    },
    {
        path: '/my',
        name: 'My',
        redirect:'/my/home',
        component: () => import('@/views/index.vue'),
        children:[
            {
                path:'home',
                component:()=>import('@/views/home/my.vue'),
                name:'MyHome',
                meta:{
                    title:'咨询',
                    isShare: false,
                }
            },
            {
                path:'message',
                component:()=>import('@/views/my/message.vue'),
                name:'MyMessage',
                meta:{
                    title:'账号信息',
                    isShare: false,
                }
            },
            {
                path:'appointment',
                component:()=>import('@/views/my/appointment.vue'),
                name:'MyAppointment',
                meta:{
                    title:'预约咨询管理',
                    isShare: false,
                }
            },
            {
                path:'crisis',
                component:()=>import('@/views/my/crisis.vue'),
                name:'MyCrisis',
                meta:{
                    title:'危机干预名单',
                    isShare: false,
                }
            },
            {
                path:'crisisRelieve',
                component:()=>import('@/views/my/crisis-relieve.vue'),
                name:'MyCrisisRelieve',
                meta:{
                    title:'危机解除名单',
                    isShare: false,
                }
            },
            {
                path:'intervention',
                component:()=>import('@/views/my/crisis-intervention.vue'),
                name:'MyIntervention',
                meta:{
                    title:'危机干预',
                    isShare: false,
                }
            },
            {
                path:'psychologica',
                component:()=>import('@/views/my/psychologica.vue'),
                name:'MyPsychologica',
                meta:{
                    title:'心理教学资源',
                    isShare: false,
                }
            },
            {
                path:'student',
                component:()=>import('@/views/my/student.vue'),
                name:'MyStudent',
                meta:{
                    title:'学生教学资源',
                    isShare: false,
                }
            },
            {
                path:'family',
                component:()=>import('@/views/my/family.vue'),
                name:'MyFamily',
                meta:{
                    title:'家庭教育课程',
                    isShare: false,
                }
            },
            {
                path:'courseDetail',
                component:()=>import('@/views/my/courseDetail.vue'),
                name:'MycourseDetail',
                meta:{
                    title:'课程详情',
                    isShare: false,
                }
            },
        ]
    },
    {
        path: '/bindWecomOk',
        name: 'BindWecomOk',
        component:()=>import('@/views/bindWecomOk/index.vue')
    },
    {
        path: '/record',
        name: 'Record',
        redirect:'/record/index',
        component: () => import('@/views/index.vue'),
        children:[
            {
                path:'index',
                component:()=>import('@/views/record/index.vue'),
                name:'recordIndex',
                meta:{
                    title:'学生档案',
                    isShare: false,
                }
            },
            {
                path:'detail',
                component:()=>import('@/views/record/detail.vue'),
                name:'recordDetail',
                meta:{
                    title:'个人心理档案',
                    isShare: false,
                }
            },
            {
                path:'info',
                component:()=>import('@/views/record/info.vue'),
                name:'recordInfo',
                meta:{
                    title:'编辑基本信息',
                }
            },
            {
                path:'address',
                component:()=>import('@/views/record/address.vue'),
                name:'recordaAddress',
                meta:{
                    title:'完善学生档案信息',
                }
            },
            {
                path:'hobby',
                component:()=>import('@/views/record/hobby.vue'),
                name:'recordaHobby',
                meta:{
                    title:'完善学生档案信息',
                }
            },
            {
                path:'case',
                component:()=>import('@/views/record/case.vue'),
                name:'recordaCase',
                meta:{
                    title:'完善学生档案信息',
                }
            },
            {
                path:'family',
                component:()=>import('@/views/record/family.vue'),
                name:'recordaFamily',
                meta:{
                    title:'完善学生档案信息',
                }
            },
            {
                path:'add-family',
                component:()=>import('@/views/record/add-family.vue'),
                name:'recordaAddFamily',
                meta:{
                    title:'新增家庭成员',
                }
            },
            {
                path:'experience',
                component:()=>import('@/views/record/experience.vue'),
                name:'recordaExperience',
                meta:{
                    title:'完善学生档案信息',
                }
            },
            {
                path:'add-experience',
                component:()=>import('@/views/record/add-experience.vue'),
                name:'recordaAddExperience',
                meta:{
                    title:'新增学习经历',
                }
            },
            {
                path:'live',
                component:()=>import('@/views/record/live.vue'),
                name:'recordaLive',
                meta:{
                    title:'完善学生档案信息',
                }
            },
            {
                path:'add-live',
                component:()=>import('@/views/record/add-live.vue'),
                name:'recordaAddLive',
                meta:{
                    title:'新增重大生活事件',
                }
            },
        ]
    },
    {
        path: '/bindWecom',
        name: 'BindWecom',
        component: () => import('@/views/bindWecom/index.vue'),
        children:[
            {
                path:'index',
                component:()=>import('@/views/bindWecom/index.vue'),
                name:'BindWecom-Index',
                meta:{
                    title:'',
                    isShare: false,
                }
            }
        ]
    },
    {
        path: '/wxworkLogin',
        name: 'WXWorkLogin',
        redirect:'/wxworkLogin/index',
        component: () => import('@/views/index.vue'),
        children:[
            {
                path:'index',
                component:()=>import('@/views/wxworkLogin/index.vue'),
                name:'WXWorkLogin-Index',
                meta:{
                    title:'',
                    isShare: false,
                }
            },
        ]
    },
    {
        path: '/authCallback',
        name: 'AuthCallback',
        redirect:'/authCallback/index',
        component: () => import('@/views/index.vue'),
        children:[
            {
                path:'index',
                component:()=>import('@/views/authCallback/index.vue'),
                name:'AuthCallback-Index',
                meta:{
                    title:'',
                    isShare: false,
                }
            },
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404.vue'),
    },

]

const router = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const whiteList = ['/authCallback', '/bindWecom', '/bindWecomOk', '/authCallback', '/wxworkLogin', '/androidIos','404','/login','/bindWecom/index']
router.beforeEach(async (to, from, next) => {
    NProgress.start()
    const gxOriginBool = store.state.originBool
     if (!gxOriginBool && location.origin === 'https://gxys.mpt.cn') {
         store.commit('SET_ORIGINBOOL', true)
     }
     // https://inpsy.cn  // 心悦成长
     const xyOriginBool = store.state.xyOriginBool
     if (!xyOriginBool && location.origin === 'https://inpsy.cn') {
         store.commit('SET_XYORIGINBOOL', true)
     }
     // https://mental.kh430.cn  // 全通教育
     const qtOriginBool = store.state.qtOriginBool
     if (!qtOriginBool && location.origin === 'https://mental.kh430.cn') {
         store.commit('SET_QTORIGINBOOL', true)
     }
     // https://mx.gdapsy.com  // 广东省应用心理研究会
     const gdOriginBool = store.state.qtOriginBool
     if (!gdOriginBool && location.origin === 'https://mx.gdapsy.com') {
         store.commit('SET_GDORIGINBOOL', true)
     }
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    const token = store.getters.token || ''
    // console.log('store',store);
    // console.log('345wx_token',token);
    // console.log('from',from);
    // console.log('to',to);
    if (!token || to.query.jsCode || to.query.userId) {
        const white = whiteList.find(item => to.path.indexOf(item) !== -1)
        // console.log('white',white);
          if (white) {
              next()
              NProgress.done()
          } else {
              next('/login')
              NProgress.done()
          }
    } else {
        /* const white = whiteList.find(item => to.path.indexOf(item) !== -1)
          if (white) {
              next()
              NProgress.done()
          } else {
              NProgress.done()
          } */
          next()
          NProgress.done()

    }
});

// 全局后置守卫
router.afterEach((to,form)=>{
    // let url =  to.fullPath
    let url =  location.href.split('?')[0]
})
export default router
