import axios from 'axios'
import { Notify } from 'vant'
import store from '@/store'

const createService=baseURL=>{
    const service = axios.create({
        baseURL,
        timeout: 5000
    })
    service.interceptors.request.use(
        config => {
            // console.log(12,config);
            if(config.url==="/wechatPub/wxPublicShare"){
                return config
            }
            if(config.url==='/upload'){
                config.headers['Content-Type']='multipart/form-data'
                config.headers['Access-Token']=store.state.token
                return config
                // config.Access-Token=store.store.token    q
            }
            if (store.getters.pageCode) {
                config.headers['pageCode'] = store.getters.pageCode
            }
            if (store.getters.token) {
                config.headers['X-token'] = store.getters.token
                if (config.method === 'get') {
                    if (config.params) {
                        config.params.token = store.getters.token
                    } else {
                        config.params = {
                            token: store.getters.token
                        }
                    }
                } else {
                    if (config.data) {
                        config.data.token = store.getters.token
                    } else {
                        config.data = {
                            token: store.getters.token
                        }
                    }
                }
            }
            return config
        },
        error => {
            console.log(error)
            return Promise.reject(error)
        }
    )
    
    service.interceptors.response.use(
        response => {
            const res = response.data
            if (res.code !== 0) {
                Notify({
                    message: res.msg || '网络错误，请重试',
                    type: 'danger',
                    duration: 2000
                })
                return Promise.reject(new Error(res.message || 'Error'))
            } else {
                return res
            }
        },
        error => {
            Notify({
                message: error || '网络错误，请重试',
                type: 'danger',
                duration: 2000
            })
            return Promise.reject(error)
        }
    )
    return service
}

// const request=createService('https://p.mpt.cn/mpt-public-api')
const request=createService('https://pmtadmin3.uelink.com.cn/orgAdmin')
// const request=createService('http://192.168.2.150:8088')
const request2=createService('https://ac.mpt.cn/ancare-api')

export {request,request2}
